<template>
  <skin-sensitivity-template
    :skin-reacts-to-products="skinReactsToProducts"
    :safe-cleansers="safeCleansers"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import { isEmpty } from 'lodash';

import SkinSensitivityTemplate from '@/modules/questionnaire/components/steps/common/medical-background/skin-sensitivity/SkinSensitivityTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { requiredField, requiredArrayField } = fieldBuilder;

const FIELDS = [requiredField('skinReactsToProducts'), requiredArrayField('safeCleansers')];

export default {
  name: 'SkinSensitivity',
  components: { SkinSensitivityTemplate },
  mixins: [makeStep(FIELDS)],
  watch: {
    skinReactsToProducts(newValue, oldValue) {
      if (!oldValue && !isEmpty(this.safeCleansers)) {
        this.showNextStep();
      } else {
        this.scrollTo('#safe-cleaners');
      }
    }
  }
};
</script>
