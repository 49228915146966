<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['skinReactsToProducts'])">
      <single-answer-question
        title="label.creamSelection"
        :value="skinReactsToProducts"
        :options="$options.yesNoResponseOptions"
        yes-no
        @input="onFieldChange('skinReactsToProducts', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['safeCleansers'])">
      <multi-answer-question
        id="safe-cleaners"
        title="label.safeCleansers"
        :value="safeCleansers"
        :options="$options.safeCleansersOptions"
        @input="onFieldChange('safeCleansers', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { SAFE_CLEANSER } from '@/modules/questionnaire/api/constants';
import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

const SAFE_CLEANSERS_OPTIONS = [
  { value: SAFE_CLEANSER.WIPES, text: 'safeCleanser.wipes' },
  { value: SAFE_CLEANSER.CLEANSING_OIL, text: 'safeCleanser.cleansingOil' },
  { value: SAFE_CLEANSER.SOAP, text: 'safeCleanser.soap' },
  { value: SAFE_CLEANSER.CLEANSING_MILK, text: 'safeCleanser.cleansingMilk' },
  { value: SAFE_CLEANSER.MICELLAR_WATER, text: 'safeCleanser.micellarWater' },
  { value: SAFE_CLEANSER.CREAM_CLEANSER, text: 'safeCleanser.creamCleanser' }
];

export default {
  name: 'SkinSensitivityTemplate',
  components: { MultiAnswerQuestion, SingleAnswerQuestion, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  safeCleansersOptions: SAFE_CLEANSERS_OPTIONS,
  props: {
    skinReactsToProducts: {
      type: String,
      required: true
    },
    safeCleansers: {
      type: Array,
      required: true
    }
  }
};
</script>
